@import url(https://rsms.me/inter/inter.css);
@import url(https://rsms.me/inter/inter.css);
html { font-family: 'Inter', sans-serif; }


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
	font-size: 1em;
  box-sizing: border-box;
  background: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *:before, *:after {
  box-sizing: inherit;
}

p {
  font-size: 1rem;
}

html { font-family: 'Inter', sans-serif; }

.inter{
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

p, h1 {
  font-family:'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}


body{
  background-color: rgba(0,0,0,1);
}


.slack{
  position: fixed;
  bottom: 0;
  left: 0;
  width:100vw;
  padding: .75em;
  background: black;
  border-top: 1px solid white;
}

.logo{
  width: 100px;
  position: fixed;
  top: 5em;
  right: 5em;
}


.carousel {
  position: fixed;
  top: 0;
  left: 0;
	height:100%;
	margin: 0;
  width: 100%;
  z-index: -1;
  background-color: rgba(0,0,0,1);
}



.image-slide {
	height: 100%;
	width: 100%;
  -webkit-transition: background-image .3s ease-in-out;
  transition: background-image .3s ease-in-out;
  opacity: .3;
  
}

.slide-arrow {
	color: white;
	cursor: pointer;
	font-size: 2rem;
	position: fixed;
	top: 50%;
}

.slide-arrow.right {
	right: 1rem;
}

.slide-arrow.left {
	left: 1rem;
}

